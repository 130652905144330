<script>
	export let name;
	window.location.replace(`https://apps.apple.com/app/apple-store/id1629430470?pt=125182831&ct=app-share-link&mt=8`);
</script>

<main>
	<h1>Hello</h1>
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
		max-width: 240px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 100;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>
